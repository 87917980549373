/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "api";
import { Store, UpdateStore } from "StoreContext";
import qs from "qs";

import {
  Badge,
  Card,
  CardHeader,
  Button,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import AddCandidate from "./AddCandidate";
import EditCandidate from "./EditCandidate";
import Header from "components/Headers/Header.js";
import { toast } from "react-toastify";
const Candidates = ({ status }) => {
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [candidate, setCandidate] = useState({});
  const { candidates } = Store();
  const updateStore = UpdateStore();
  const history = useHistory();
  const { pathname } = window.location;

  console.log("🚀 ~ Candidates ~ candidate:", candidate);
  useEffect(() => {
    getCandidates();
  }, []);

  const getCandidates = async () => {
    const data = await api("get", "/users");

    if (pathname === "/admin/invited-candidates") {
      updateStore({
        candidates: data.users.filter((a) => a.test_conducted === false),
      });
    } else if (pathname === "/admin/assessment-participants") {
      updateStore({
        candidates: data.users.filter((a) => a.test_conducted === true),
      });
    } else {
      updateStore({ candidates: data.users });
    }
  };

  const handleModal = () => {
    setOpenModal((open) => !open);
    return openModal;
  };

  const handleEditModal = (item) => {
    setCandidate(item || candidate);
    setEditModal((open) => !open);
    return openModal;
  };

  const handleDelete = (id) => {
    api("delete", `/users/${id}`).then(() => {
      toast.success("Candidate deleted successfully");
      getCandidates();
    });
  };

  const handleNavigation = (item) => {
    const skills = item?.skills || [];
    const queryString = qs.stringify({ skills });
    history.push(`/admin/questions/${item?._id}?${queryString}`);
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">
                    {pathname === "/admin/invited-candidates"
                      ? "Invited Candidates"
                      : pathname === "/admin/assessment-participants"
                      ? "Assessment Participants"
                      : "Candidates"}
                  </h3>
                  <Button
                    color="primary"
                    href="#pablo"
                    onClick={handleModal}
                    size="md"
                  >
                    <i className="ni ni-fat-add"></i>
                    Add Candidate
                  </Button>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead
                  className="thead-light"
                  style={{
                    position: "sticky",
                    width: "100%",
                    top: "0px",
                  }}
                >
                  <tr>
                    <th scope="col">Name</th>
                    {/* <th scope="col">Last Name</th> */}
                    <th scope="col">Email</th>
                    <th scope="col">Status</th>
                    <th scope="col">Department</th>
                    <th scope="col">Created By</th>
                    <th scope="col">Created On</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {candidates?.length > 0 ? (
                    candidates?.map((item) => {
                      return (
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media style={{ display: "flex", gap: "4px" }}>
                                <span
                                  className="mb-0 text-sm"
                                  title={item.firstName}
                                >
                                  {item.firstName}
                                </span>
                                <span
                                  className="mb-0 text-sm"
                                  title={item.lastName}
                                >
                                  {item.lastName}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          {/* <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span
                                  className="mb-0 text-sm"
                                  title={item.lastName}
                                >
                                  {item.lastName?.length > 30
                                    ? item.lastName?.substring(0, 30) + "..."
                                    : item.lastName}
                                </span>
                              </Media>
                            </Media>
                          </th> */}
                          <td title={item.email}>
                            {item.email?.length > 30
                              ? item.email?.substring(0, 30) + "..."
                              : item.email}
                          </td>
                          <td>
                            <Badge color="" className="badge-dot mr-4">
                              <i className="bg-success" />
                              {item.test_conducted === "true"
                                ? "Assessment Completed"
                                : item.test_conducted === "expire"
                                ? "Expired"
                                : "Invited"}
                            </Badge>
                          </td>
                          <td>{item.department}</td>
                          <td>
                            {item.createdByAdmin === true ? "Admin" : "Manager"}
                          </td>
                          <td>{new Date(item.createdAt).toDateString()}</td>

                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                {item.test_conducted && (
                                  <DropdownItem
                                    onClick={() =>
                                      history.push(
                                        `/admin/candidates/${item?._id}`
                                      )
                                    }
                                  >
                                    Result
                                  </DropdownItem>
                                )}
                                <DropdownItem
                                  href="#pablo"
                                  onClick={() => handleEditModal(item)}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={() => handleNavigation(item)}
                                >
                                  Custom Questions
                                </DropdownItem>
                                <DropdownItem
                                  className="text-danger"
                                  onClick={() => handleDelete(item?._id)}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <div>
                      <p className="col">No data found</p>
                    </div>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        {openModal && (
          <AddCandidate
            openModal={openModal}
            handleModal={handleModal}
            getCandidates={getCandidates}
          />
        )}
        {editModal && (
          <EditCandidate
            openModal={editModal}
            handleModal={handleEditModal}
            candidate={candidate}
            setCandidate={setCandidate}
            getCandidates={getCandidates}
          />
        )}
      </Container>
    </>
  );
};

export default Candidates;
