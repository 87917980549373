import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import { Select } from "antd";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
// core components

const { Option } = Select;

const EditQuestion = ({
  openModal,
  handleModal,
  question,
  setQuestion,
  getQuestions,
}) => {
  let [skills] = useState([
    "HTML",
    "CSS",
    "JavaScript",
    "React",
    "Node",
    "Express",
  ]);
  console.log(question, "qstn");
  const handleInput = (e) => {
    setQuestion({ ...question, [e.target.name]: e.target.value });
  };
  const trueOption = question.options.find((option) => option.correct);
  const [options, setOptions] = useState(
    question.options ? question.options : ""
  );

  const [selectedOption, setSelectedOption] = useState(
    trueOption ? trueOption.text : ""
  ); // State to track the selected option

  const handleOptionChange = (event) => {
    const updatedOptions = options.map((option) =>
      option.text === event
        ? { ...option, correct: true }
        : { ...option, correct: false }
    );
    setOptions(updatedOptions);
  };
  const handleInputChange = (index, newText) => {
    const updatedOptions = options.map((option, i) =>
      i === index ? { ...option, text: newText } : option
    );
    setOptions(updatedOptions);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const data = { text: question.text, options };
    console.log("data", data);
    if (question?.type == "multipleChoice") {
      const isEmpty = options.some((option) => option.text.trim() === "");
      if (isEmpty) {
        toast.error("All fields are required");
        return;
      }
    }
    if (!question.text) {
      toast.error("All fields are required");
    } else {
      console.log("selectedOption", selectedOption);
      api("put", `/questions/${question._id}`, data).then((res) => {
        toast.success("Question Update successfully");
        getQuestions();
        handleModal();
      });
    }
  };

  return (
    <>
      <Modal isOpen={openModal} size="md" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Edit question
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="9" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Question</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter Question Text Here"
                          type="text"
                          value={question?.text}
                          name="text"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                    {question?.type === "multipleChoice" && (
                      <Col lg="9" style={{ margin: "auto" }}>
                        <FormGroup>
                          <label className="form-control-label">
                            Options (select correct one)
                          </label>
                          {options?.map((option, index) => (
                            <div
                              key={index}
                              className="mb-2 d-flex align-items-center"
                            >
                              <label>
                                <Input
                                  type="radio"
                                  style={{ backgroundColor: "green" }}
                                  name="options"
                                  // value={option.correct}
                                  checked={option.correct}
                                  onChange={() =>
                                    handleOptionChange(option?.text)
                                  }
                                />{" "}
                              </label>
                              <Input
                                key={index}
                                className="form-control-alternative text-default"
                                required={true}
                                placeholder={`Enter Option ${
                                  index + 1
                                } Text Here`}
                                type="text"
                                value={option?.text || ""}
                                onChange={(e) =>
                                  handleInputChange(index, e.target.value)
                                }
                              />
                            </div>
                          ))}
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  {/* <Row>
                    <Col lg="9" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Technology</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          type="select"
                          value={question.technology}
                          name="technology"
                          onChange={handleInput}
                        >
                          <option value="">Select Technology </option>
                          <option value="HTML">HTML</option>
                          <option value="CSS">CSS</option>
                          <option value="JavaScript">JavaScript</option>
                          <option value="React">React</option>
                          <option value="Node">Node</option>
                          <option value="Express">Express</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row> */}
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Update
          </Button>

          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditQuestion;
