import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Button,
  Dropdown,
} from "reactstrap";
import api from "api/index";
// import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "components/Headers/Header.js";
import EditQuestion from "./EditQuestion";
import AddQuestion from "./AddNewQuestion";
import CustomCategory from "views/customCategory/CustomCategory";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const Questions = () => {
  // const history = useHistory();
  const [questions, setQuestions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [question, setQuestion] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("ALL");

  const scrollToSectionRef = useRef(null); // Create a ref for the target section

  const handleScroll = () => {
    console.log("scrollToSectionRef:", scrollToSectionRef.current); // Debug log
    if (scrollToSectionRef.current) {
      scrollToSectionRef.current.scrollIntoView({
        behavior: "smooth", // Enable smooth scrolling
        block: "start", // Align the section at the start
      });
    } else {
      // console.warn("Target element not found.");
    }
  };

  useEffect(() => {
    getQuestions();
    getCategories();
  }, []);

  const handleModal = () => {
    setOpenModal((open) => !open);
    return openModal;
  };

  const getQuestions = async () => {
    setLoading(true);
    const res = await api("get", "/questions");
    setQuestions(res?.questions);
    setLoading(false);
  };

  const getCategories = async () => {
    try {
      const res = await api("get", "/category");
      setCategories(res.category); // Assuming your backend returns an array of categories in res.data
    } catch (error) {
      console.error("Error fetching test title:", error);
    }
  };

  const handleDeleteQustn = (item) => {
    return api("delete", `/questions/${item?._id}`)
      .then((res) => {
        getQuestions();
        toast.success("Question deleted");
      })
      .catch((err) => {
        console.error("Error deleting question:", err);
        toast.error("Failed to delete question");
      });
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const filter = (questions) => {
    if (selectedCategory === "ALL") {
      return questions;
    } else {
      return questions.filter((item) => {
        return item.category === selectedCategory;
      });
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleEditModal = (item) => {
    setQuestion(item || question);
    setEditModal((open) => !open);
    return editModal;
  };
  console.log("🚀 ~ Questions ~ questions:", questions);

  return (
    <>
      <Header />
      <div
        // fluid
        className="mt--7"
        // style={{
        //   display: "flex",
        //   width: "100%",
        //   flexDirection: "column",
        //   justifyContent: "center",
        //   alignItems: "start",
        //   padding: "0px 23px",
        // }}
      >
        <Container
          fluid
          //  style={{ width: "100%" }}
        >
          <CustomCategory
            handleModalQuestion={handleModal}
            handleScroll={handleScroll}
          />
        </Container>
        <Container
          fluid
          //  style={{ width: "100%" }}
        >
          <Row>
            <div className="col-12">
              {/* Changed to col-12 for full width  */}
              <Card className="shadow border-0">
                <CardHeader className="border-0">
                  <div className="d-flex justify-content-between">
                    <h3 className="mb-0">View Test Questions</h3>
                    <div className="d-flex align-items-center">
                      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                        <DropdownToggle caret color="primary">
                          {selectedCategory !== "ALL"
                            ? selectedCategory
                            : "Test Title"}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => setSelectedCategory("ALL")}
                          >
                            ALL
                          </DropdownItem>
                          {categories.map((category) => (
                            <DropdownItem
                              key={category._id}
                              onClick={() => setSelectedCategory(category.name)}
                            >
                              {category.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {/* <Button
                        color="primary"
                        href="#pablo"
                        // onClick={handleModal}
                        size="md"
                      >
                        <i className="ni ni-fat-add"></i>
                        Add Question To a Test
                      </Button> */}
                    </div>
                  </div>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead
                    className="thead-light"
                    style={{
                      position: "sticky",
                      width: "100%",
                      top: "0px",
                    }}
                  >
                    <tr>
                      <th scope="col">Question</th>
                      <th scope="col">Test Title</th>
                      <th scope="col">Type</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody
                    ref={scrollToSectionRef} // Assign the ref
                  >
                    {loading ? (
                      <h3 className="ml-3">Loading...</h3>
                    ) : (
                      <>
                        {filter(questions).length > 0 ? (
                          filter(questions).map((item) => (
                            <tr key={item._id}>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <Media>
                                    <span
                                      className="mb-0 text-sm"
                                      title={item.text}
                                    >
                                      {item?.text.length > 65
                                        ? `${item.text.slice(0, 65)}...`
                                        : item.text}
                                    </span>
                                  </Media>
                                </Media>
                                {item?.options
                                  ?.filter((option) => option.text) // Filter out options without a text property
                                  .map((option, optionIndex) => (
                                    <div
                                      key={optionIndex}
                                      className="d-flex flex-column justify-content-between mt-2"
                                    >
                                      <div className="w-33">
                                        <Media>
                                          <span
                                            className="mb-0 text-xs text-wrap"
                                            style={{
                                              color: option?.correct
                                                ? "#2ECE89"
                                                : "gray",
                                              fontWeight: option?.correct
                                                ? "900"
                                                : "600",
                                            }}
                                            title={option?.text}
                                          >
                                            {optionIndex + 1}
                                            {") "}
                                            {option?.text?.length > 65
                                              ? option.text.slice(0, 65)
                                              : option.text}
                                          </span>
                                        </Media>
                                      </div>
                                    </div>
                                  ))}
                              </th>
                              <td>{item?.category}</td>
                              <td>
                                {item?.type === "multipleChoice"
                                  ? "MCQS"
                                  : "Text"}
                              </td>

                              <td className="text-right">
                                <EditIcon
                                  onClick={() => handleEditModal(item)}
                                  sx={{
                                    cursor: "pointer",
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "15px",
                                  }}
                                  fontSize="medium"
                                />
                                <DeleteIcon
                                  onClick={() => handleDeleteQustn(item)}
                                  sx={{
                                    cursor: "pointer",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                  fontSize="medium"
                                />
                              </td>
                              {/* <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      href="#pablo"
                                      onClick={() => handleEditModal(item)}
                                    >
                                      Edit
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => handleDeleteQustn(item)}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td> */}
                            </tr>
                          ))
                        ) : (
                          <div>
                            <p className="col">No data found</p>
                          </div>
                        )}
                      </>
                    )}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
        {editModal && (
          <EditQuestion
            openModal={editModal}
            handleModal={handleEditModal}
            question={question}
            setQuestion={setQuestion}
            getQuestions={getQuestions}
          />
        )}
        {openModal && (
          <AddQuestion
            openModal={openModal}
            handleModal={handleModal}
            getQuestions={getQuestions}
          />
        )}
      </div>
    </>
  );
};

export default Questions;
