/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

const AddManager = ({ openModal, handleModal, getManagers }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "manager",
    department: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  let lastToastTime = 0;

  const handleChange = (event) => {
    const { name, value } = event.target;
    const now = Date.now();
    const regex = /^[a-zA-Z\s.]*$/;

    if ((name === "firstName" || name === "lastName") && !regex.test(value)) {
      if (now - lastToastTime >= 60000) {
        toast.error("Only alphabets and dots are allowed.");
        lastToastTime = now;
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const nameRegex = /^[A-Za-z.]+$/;

    if (!formData?.firstName.trim()) {
      toast.error("First name is required.");
      return false;
    }

    if (!formData?.lastName.trim()) {
      toast.error("Last name is required.");
      return false;
    }

    if (!formData?.email.trim()) {
      toast.error("Email is required.");
      return false;
    }

    if (!formData?.department.trim()) {
      toast.error("Department is required.");
      return false;
    }

    if (!emailRegex.test(formData.email)) {
      toast.error("Invalid email format");
      return;
    }

    // if (!nameRegex.test(formData.name)) {
    //   toast.error("Name cannot contain special characters");
    //   return;
    // }

    if (formData.password.length < 8 || formData.password.length > 20) {
      toast.error("Password must be between 8 and 20 characters.");
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    api("post", `/managers`, formData, {}).then((res) => {
      toast.success("Manager created successfully");
      handleModal();
      getManagers();
    });
  };

  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    getDepartment();
  }, []);

  const getDepartment = async () => {
    try {
      const res = await api("get", "/department");
      setDepartments(res?.department || []); // Ensure data is set correctly
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Create a manager
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">First Name</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter name here"
                          type="text"
                          value={formData.firstName}
                          name="firstName"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Last Name</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter name here"
                          type="text"
                          value={formData.lastName}
                          name="lastName"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Email
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Enter email"
                          type="text"
                          value={formData.email}
                          name="email"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Department
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Enter Department"
                          type="select"
                          value={formData.department ?? ""}
                          name="department"
                          onChange={handleChange}
                        >
                          <option value="" disabled>
                            Select a department
                          </option>
                          {departments.map((dept, index) => (
                            <option key={index} value={dept.name}>
                              {dept.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Password</label>
                        <div className="input-group Box-Shadow">
                          <Input
                            className="form-control-alternative text-default"
                            required={true}
                            placeholder="Password"
                            type={showPassword ? "text" : "password"}
                            value={formData.password}
                            name="password"
                            onChange={handleChange}
                          />
                          <div className="input-group-append">
                            <IconButton onClick={toggleShowPassword}>
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Confirm Password
                        </label>
                        <div className="input-group Box-Shadow">
                          <Input
                            className="form-control-alternative text-default"
                            placeholder="Confirm Password"
                            required={true}
                            type={showConfirmPassword ? "text" : "password"}
                            value={formData.confirmPassword}
                            name="confirmPassword"
                            onChange={handleChange}
                          />
                          <div className="input-group-append">
                            <IconButton onClick={toggleShowConfirmPassword}>
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">Role</label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter Role"
                          type="text"
                          value={formData.role}
                          name="role"
                          onChange={handleChange}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Create
          </Button>
          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddManager;
