import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import { Select } from "antd";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
// core components

const { Option } = Select;

const EditCandidate = ({
  openModal,
  handleModal,
  candidate,
  setCandidate,
  getCandidates,
}) => {
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      const res = await api("get", "/category");
      setCategories(res?.category); // Assuming your backend returns categories in res.data.category
    } catch (error) {
      console.error("Error fetching test title:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleInput = (e) => {
    setCandidate({ ...candidate, [e.target.name]: e.target.value });
  };

  const handleSkillsInput = (value) => {
    setCandidate({ ...candidate, skills: value });
  };

  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    getDepartment();
  }, []);

  const getDepartment = async () => {
    try {
      const res = await api("get", "/department");
      setDepartments(res?.department || []); // Ensure data is set correctly
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !candidate.firstName ||
      !candidate.email ||
      !candidate.department ||
      candidate.skills.length < 1 ||
      !candidate.time_in_minutes
    ) {
      toast.error("All fields are required");
    } else {
      api("post", "/users", candidate).then((res) => {
        toast.success("Candidate updated successfully");
        getCandidates();
        handleModal();
      });
    }
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Edit a candidate
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="9" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter candidate's name here"
                          type="text"
                          value={candidate.firstName}
                          name="firstName"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="9" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Email</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter candidate's email here"
                          type="email"
                          disabled
                          value={candidate?.email}
                          name="email"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="9" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Department</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          type="select"
                          value={candidate?.department ?? ""}
                          name="department"
                          onChange={handleInput}
                        >
                          <option value="" disabled>
                            Select a department
                          </option>
                          {departments.map((dept, index) => (
                            <option key={index} value={dept.name}>
                              {dept.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="9" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Test Title</label>
                        <Select
                          className="form-control-alternative text-default"
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          placeholder="Select candidate's test title"
                          value={candidate.skills}
                          onChange={handleSkillsInput}
                        >
                          {categories?.map((category) => (
                            <Option key={category._id} value={category.name}>
                              {category.name}
                            </Option>
                          ))}
                        </Select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="9" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Test Duration
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          type="select"
                          value={candidate?.time_in_minutes}
                          name="time_in_minutes"
                          onChange={handleInput}
                        >
                          <option value="">Select Duration </option>
                          <option value="10">10 minutes</option>
                          <option value="15">15 minutes</option>
                          <option value="20">20 minutes</option>
                          <option value="25">25 minutes</option>
                          <option value="30">30 minutes</option>
                          <option value="35">35 minutes</option>
                          <option value="40">40 minutes</option>
                          <option value="45">45 minutes</option>
                          <option value="50">50 minutes</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Update
          </Button>
          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditCandidate;
