// reactstrap components
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import api from "api";
import { Store } from "StoreContext";
import {
  Card,
  CardHeader,
  Button,
  Table,
  Container,
  Row,
  FormGroup,
  Input,
  Col,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.js";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const ViewArchivedData = () => {
  const history = useHistory();
  const [candidate, setCandidate] = useState({});
  const [candidateResult, setCandidateResult] = useState([]);
  console.log("🚀 ~ ViewArchivedData ~ candidateResult:", candidateResult);
  const [isLoading, setIsLoading] = useState(true);
  const [Loading, setLoading] = useState(false);

  const { user } = Store();
  const { userId } = useParams();

  const getCandidates = async () => {
    setIsLoading(true);
    const data = await api("get", `/results/${userId}`);
    setCandidate(data?.user);
    setCandidateResult(data?.result);
    setIsLoading(false);
  };

  const handleArchived = async () => {
    setLoading(true);

    try {
      await api("put", `/results/archived/${candidateResult[0]?._id}`, {
        archived: false,
      });
      toast.success("Unarchived successfully");
      history.push("/admin/archived-result");
    } catch (error) {
      console.error("Failed to fetch archived results:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCandidates();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      })
      .replace("", "");
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row className="mb-4">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-1">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">Performa</h3>
                  <h3>
                    {candidateResult?.length > 0 &&
                    candidateResult[0]?.archivedByAdmin
                      ? "Archived by Admin"
                      : "Archived by Manager"}
                  </h3>

                  <Button
                    disabled={Loading}
                    color="primary"
                    href="#pablo"
                    onClick={handleArchived}
                    size="md"
                  >
                    {Loading ? (
                      <CircularProgress color="success" size={24} />
                    ) : (
                      "Unarchive"
                    )}
                  </Button>
                </div>
              </CardHeader>
              <Row>
                <Col>
                  <div className="pl-lg-4 pr-4 pt-4">
                    <Row>
                      <Col lg="4" style={{ margin: "auto" }}>
                        <FormGroup>
                          <label className="form-control-label">Name</label>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={candidate?.firstName || "N/A"}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col lg="4" style={{ margin: "auto" }}>
                        <FormGroup>
                          <label className="form-control-label">
                            Last Name
                          </label>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={candidate?.lastName || "N/A"}
                            disabled
                          />
                        </FormGroup>
                      </Col> */}

                      <Col lg="4" style={{ margin: "auto" }}>
                        <FormGroup>
                          <label className="form-control-label">
                            Phone Number
                          </label>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={candidate?.contact_no || "N/A"}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4" style={{ margin: "0" }}>
                        <FormGroup>
                          <label className="form-control-label">Email</label>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={candidate?.email || "N/A"}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col lg="4" style={{ margin: "0" }}>
                        <FormGroup>
                          <label className="form-control-label">
                            Date of Birth
                          </label>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={
                              candidate.dob
                                ? new Date(candidate.dob).toDateString()
                                : "N/A"
                            }
                            disabled
                          />
                        </FormGroup>
                      </Col> */}
                      {/* <Col lg="4" style={{ margin: "auto" }}>
                        <FormGroup>
                          <label className="form-control-label">Religion</label>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={candidate.sect || "N/A"}
                            disabled
                          />
                        </FormGroup>
                      </Col> */}
                    </Row>

                    <Row>
                      {/* <Col lg="4" style={{ margin: "auto" }}>
                        <FormGroup>
                          <label className="form-control-label">
                            Applying for the post
                          </label>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={candidate?.post || "N/A"}
                            disabled
                          />
                        </FormGroup>
                      </Col> */}

                      <Col lg="6" style={{ margin: "auto" }}>
                        <FormGroup>
                          <label className="form-control-label">
                            Total professional experience
                          </label>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={candidate?.total_experience || "N/A"}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6" style={{ margin: "auto" }}>
                        <FormGroup>
                          <label className="form-control-label">
                            Test submitted time
                          </label>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={new Date(
                              candidate.updatedAt
                            ).toLocaleString()}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col lg="4" style={{ margin: "auto" }}>
                        <FormGroup>
                          <label className="form-control-label">
                            Expected date of joining
                          </label>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={formatDate(candidate.joining_time)}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4" style={{ margin: "auto" }}>
                        <FormGroup>
                          <label className="form-control-label">
                            Test Category
                          </label>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={candidate.skills?.join(", ") || "N/A"}
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="4" style={{ margin: "auto" }}>
                        <FormGroup>
                          <label className="form-control-label">Address</label>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={candidate.address || "N/A"}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                    {user?.role === "hr" && (
                      <Row>
                        <Col lg="4" style={{ margin: "auto" }}>
                          <FormGroup>
                            <label className="form-control-label">
                              Current Salary
                            </label>
                            <Input
                              className="form-control-alternative text-default"
                              type="text"
                              value={candidate.current_salary || "N/A"}
                              disabled
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4" style={{ margin: "auto" }}>
                          <FormGroup>
                            <label className="form-control-label">
                              Expected Salary
                            </label>
                            <Input
                              className="form-control-alternative text-default"
                              type="text"
                              value={candidate.expected_salary || "N/A"}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4" style={{ margin: "auto" }}>
                          <FormGroup>
                            <label className="form-control-label">
                              Current Employer
                            </label>
                            <Input
                              className="form-control-alternative text-default"
                              type="text"
                              value={candidate?.current_employer || "N/A"}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      {/* <Col lg="4" style={{ margin: "auto" }}>
                        <FormGroup>
                          <label className="form-control-label">
                            Marital Status
                          </label>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={candidate.marital_status || "N/A"}
                            disabled
                          />
                        </FormGroup>
                      </Col> */}
                    </Row>
                    {/* 
                    <Row>
                      <Col lg="12" style={{ margin: "auto" }}>
                        <FormGroup>
                          <label className="form-control-label">
                            Reason for leaving current job
                          </label>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={candidate.leaving_reason || "N/A"}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
        </Row>
        <Row>
          <>
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <div className="col">
                {candidateResult?.map((card, index) => (
                  <Card
                    className="shadow"
                    style={{ marginBottom: "30px" }}
                    key={index}
                  >
                    <CardHeader className="border-0">
                      <div className="d-flex justify-content-between">
                        <h3 className="mb-0">Result_{index + 1}</h3>
                      </div>
                    </CardHeader>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead
                        className="thead-light"
                        style={{
                          position: "sticky",
                          width: "100%",
                          top: "0px",
                        }}
                      >
                        <tr>
                          <th scope="col">Test Title</th>
                          <th scope="col">Total Questions</th>
                          <th scope="col">Correct</th>
                          <th scope="col">Wrong</th>
                          <th scope="col">Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {card?.result_by_category?.map((tech) => (
                          <tr key={tech.id}>
                            <th>{tech?.category}</th>
                            <td>{tech?.total}</td>
                            <td>{tech?.correct}</td>
                            <td>{tech?.wrong}</td>
                            <td>{tech.percentage}</td>
                          </tr>
                        ))}
                        <tr>
                          <th>Overall</th>
                          <td>{card?.total}</td>
                          <td>{card?.total_correct}</td>
                          <td>{card?.total_wrong}</td>
                          <td>
                            {card?.overall_percentage === "NaN%"
                              ? "0%"
                              : card?.overall_percentage}
                          </td>
                        </tr>
                      </tbody>
                      <thead
                        className="thead-light"
                        style={{
                          position: "sticky",
                          width: "100%",
                          top: "0px",
                        }}
                      >
                        <tr>
                          <th scope="col">Question</th>
                          <th scope="col">Answer</th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {card?.text_answers?.map((tech) => (
                          <tr key={tech.question}>
                            <th>
                              <span title={tech.question}>
                                {tech?.question.length > 50
                                  ? `${tech.question.slice(0, 50)}...`
                                  : tech.question}
                              </span>
                            </th>
                            <td>
                              <span title={tech.answer}>
                                {tech?.answer.length > 50
                                  ? `${tech.answer.slice(0, 50)}...`
                                  : tech.answer}
                              </span>
                            </td>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        ))}
                      </tbody>
                      <thead
                        className="thead-light"
                        style={{
                          position: "sticky",
                          width: "100%",
                          top: "0px",
                        }}
                      >
                        <tr>
                          <th scope="col">Question</th>
                          <th scope="col"></th>
                          <th scope="col">Selected Answer</th>
                          <th scope="col">Correct Answer</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {card?.answers?.test?.map((tech) => (
                          <tr key={tech.question_text}>
                            <th>
                              <span title={tech.question_text}>
                                {tech?.question_text.length > 50
                                  ? `${tech.question_text.slice(0, 50)}...`
                                  : tech.question_text}
                              </span>
                            </th>
                            <td></td>
                            <td
                              style={{
                                color: tech?.correct ? "#2ECE89" : "red",
                                fontWeight: tech?.correct ? "900" : "600",
                              }}
                            >
                              <span title={tech.chosen_option}>
                                {tech?.chosen_option.length > 25
                                  ? `${tech.chosen_option.slice(0, 25)}...`
                                  : tech.chosen_option}
                              </span>
                            </td>
                            <td>
                              <span title={tech.correct_option}>
                                {tech?.correct_option.length > 25
                                  ? `${tech.correct_option.slice(0, 25)}...`
                                  : tech.correct_option}
                              </span>
                            </td>
                            <td></td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                ))}
              </div>
            )}
          </>
        </Row>
      </Container>
    </>
  );
};

export default ViewArchivedData;
