import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";
import api from "api/index";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

// core components
import Header from "components/Headers/Header.js";

import EditAnswer from "./EditAnswer";
import AddNewAnswer from "./AddNewAnswer";

const ViewAnswers = () => {
  const { number, technology } = useParams();

  const [answers, setAnswers] = useState([]);
  const [answer, setAnswer] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAnswers();
  }, []);

  const getAnswers = async () => {
    setLoading(true);
    if (!number) {
      toast.error("Question number required");
      return;
    }
    if (!technology) {
      toast.error("Test Title required");
      return;
    }
    const res = await api("get", `/answers/${number}/${technology}`);
    setAnswers(res?.answers);
    setLoading(false);
  };
  const handleEditModal = (item) => {
    setAnswer(item || answer);
    setEditModal((open) => !open);
    return editModal;
  };
  const handleModal = () => {
    setOpenModal((open) => !open);
    return openModal;
  };
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">Answers</h3>
                  {answers?.length < 4 && (
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={handleModal}
                      size="md"
                    >
                      <i className="ni ni-fat-add"></i>
                      Add New Answer
                    </Button>
                  )}
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead
                  className="thead-light"
                  style={{
                    position: "sticky",
                    width: "100%",
                    top: "0px",
                  }}
                >
                  <tr>
                    <th scope="col">Question number</th>
                    <th scope="col">answer</th>
                    <th scope="col">technology</th>
                    <th scope="col">answer status</th>

                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <h3>Loading...</h3>
                  ) : (
                    <>
                      {answers?.map((item) => {
                        return (
                          <tr>
                            <th scope="row">
                              <Media className="align-items-center">
                                <Media>
                                  <span
                                    className="mb-0 text-sm"
                                    title={item.question}
                                  >
                                    {item.question?.length > 60
                                      ? item.question?.substring(0, 60) + "..."
                                      : item.question}
                                  </span>
                                </Media>
                              </Media>
                            </th>

                            <td title={item.answer}>{item.answer}</td>
                            <td title={item.technology}>{item.technology}</td>

                            <td>{item.correct ? "correct" : "in_correct"}</td>

                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={() => handleEditModal(item)}
                                  >
                                    Edit
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>

      {editModal && (
        <EditAnswer
          openModal={editModal}
          handleModal={handleEditModal}
          answer={answer}
          setAnswer={setAnswer}
          getAnswers={getAnswers}
        />
      )}
      {openModal && (
        <AddNewAnswer
          openModal={openModal}
          handleModal={handleModal}
          getAnswers={getAnswers}
          questionNumber={number}
          technology={technology}
        />
      )}
    </>
  );
};

export default ViewAnswers;
