/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import { useEffect, useState } from "react";
import api from "api";
import { Store, UpdateStore } from "StoreContext";
import {
  // Badge,
  Card,
  CardHeader,
  Button,
  // CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  // UncontrolledTooltip,
} from "reactstrap";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// core components
import Header from "components/Headers/Header.js";
import { toast } from "react-toastify";
import AddManager from "./AddManager";
import EditManager from "./EditManager";
const Candidates = ({ status }) => {
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [managers, setManagers] = useState({});
  const { manager } = Store();
  const updateStore = UpdateStore();

  const role = localStorage.getItem("role");
  console.log("🚀 ~ Candidates ~ manager1231:", manager);

  useEffect(() => {
    getManagers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getManagers = async () => {
    const data = await api("get", "/managers");
    updateStore({ manager: data.managers });
  };

  const handleModal = () => {
    setOpenModal((open) => !open);
    return openModal;
  };

  const handleEditModal = (item) => {
    setManagers(item || managers);
    setEditModal((open) => !open);
    return openModal;
  };

  const handleDelete = (id) => {
    if (role !== "manager") {
      api("delete", `/managers/${id}`).then(() => {
        toast.success("Manager deleted successfully");
        getManagers();
      });
    } else {
      toast.error("You are not authorized to do this");
    }
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">Managers</h3>
                  <Button
                    color="primary"
                    href="#pablo"
                    onClick={handleModal}
                    size="md"
                  >
                    <i className="ni ni-fat-add"></i>
                    Add Manager
                  </Button>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead
                  className="thead-light"
                  style={{
                    position: "sticky",
                    width: "100%",
                    top: "0px",
                  }}
                >
                  <tr>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Department</th>
                    <th scope="col">Created On</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {manager?.length > 0 ? (
                    manager?.map((item) => {
                      return (
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span
                                  className="mb-0 text-sm"
                                  title={item.firstName}
                                >
                                  {item.firstName?.length > 30
                                    ? item.firstName?.substring(0, 30) + "..."
                                    : item.firstName}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span
                                  className="mb-0 text-sm"
                                  title={item.lastName}
                                >
                                  {item.lastName?.length > 30
                                    ? item.lastName?.substring(0, 30) + "..."
                                    : item.lastName}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td title={item.email}>
                            {item.email?.length > 30
                              ? item.email?.substring(0, 30) + "..."
                              : item.email}
                          </td>
                          {/* <td>
                            <Badge color="" className="badge-dot mr-4">
                              <i className="bg-success" />
                              {item.test_conducted ? "Pending" : "Invited"}
                            </Badge>
                          </td> */}
                          <td>{item.department}</td>
                          <td>{new Date(item.createdAt).toDateString()}</td>

                          <td
                            className="text-right"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                              gap: "15px",
                            }}
                          >
                            <EditIcon
                              onClick={() => handleEditModal(item)}
                              sx={{
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                              }}
                              fontSize="medium"
                            />
                            <DeleteIcon
                              onClick={() => handleDelete(item?._id)}
                              sx={{
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                              }}
                              fontSize="medium"
                            />
                          </td>
                          {/* <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={() => handleEditModal(item)}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  className="text-danger"
                                  onClick={() => handleDelete(item?._id)}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <div>
                      <p className="col">No data found</p>
                    </div>
                  )}
                </tbody>
              </Table>

              {/* ///////////     Pagination Disabled Temp     ///////////// */}

              {/* <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter> */}
            </Card>
          </div>
        </Row>
        {openModal && (
          <AddManager
            openModal={openModal}
            handleModal={handleModal}
            getManagers={getManagers}
          />
        )}
        {editModal && (
          <EditManager
            openModal={editModal}
            handleModal={handleEditModal}
            managers={managers}
            setManagers={setManagers}
            getManagers={getManagers}
          />
        )}
      </Container>
    </>
  );
};

export default Candidates;
