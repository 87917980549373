import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";

const EditDepartment = ({
  openModal,
  handleModal,
  department,
  getDepartment,
}) => {
  const [departmentData, setDepartmentData] = useState({
    name: department.name || "", // Initialize with the passed category's name or empty string
    _id: department._id || null, // Initialize with the passed category's id or null
  });

  useEffect(() => {
    if (department) {
      setDepartmentData({
        name: department.name || "",
        _id: department._id || null,
      });
    }
  }, [department]);

  const handleInput = (e) => {
    setDepartmentData({ ...departmentData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!departmentData.name) {
      toast.error("Department name is required");
    } else {
      try {
        // If category has an _id, update it
        await api("put", `/department/${departmentData._id}`, departmentData);
        toast.success("Department updated successfully");

        getDepartment(); // Refresh categories list
        handleModal(); // Close modal
      } catch (error) {
        console.error("Error saving department:", error);
        toast.error("Error saving department");
      }
    }
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Edit a Department
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label">Name</label>
                <Input
                  className="form-control-alternative text-default"
                  required={true}
                  placeholder="Enter test title name"
                  type="text"
                  value={departmentData.name}
                  name="name"
                  onChange={handleInput}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Update
          </Button>
          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditDepartment;
