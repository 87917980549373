/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components

import { Card, CardHeader, Button, Table, Container, Row } from "reactstrap";

// core components
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import api from "api/index";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import EditDepartment from "./EditDepartment";
import AddDepartment from "./AddDepartment";

const Department = () => {
  const [department, setDepartment] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const [currentDepartment, setCurrentDepartment] = useState(null); // For tracking which category is being edited

  const handleModal = (department = null) => {
    setCurrentDepartment(department); // Set current category for editing
    setOpenModal(!openModal); // Toggle modal
  };

  const handleAddModal = () => {
    setAddModal((open) => !open);
    return addModal;
  };

  const handleDelete = async (id) => {
    try {
      await api("delete", `/department/${id}`);
      toast.success("Department deleted successfully");
      getDepartment();
    } catch (error) {
      toast.error("Failed to delete department");
    }
  };

  useEffect(() => {
    getDepartment();
  }, []);

  const getDepartment = async () => {
    try {
      const res = await api("get", "/department");
      setDepartment(res?.department);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">Departments</h3>
                  <Button
                    color="primary"
                    href="#pablo"
                    onClick={handleAddModal}
                    size="md"
                  >
                    <i className="ni ni-fat-add"></i>
                    Add Department
                  </Button>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead
                  className="thead-light"
                  style={{
                    position: "sticky",
                    width: "100%",
                    top: "0px",
                  }}
                >
                  <tr>
                    <th scope="col">Name</th>
                    {/* <th scope="col">Last Name</th> */}
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {department?.length > 0 ? (
                    department?.map((item) => {
                      return (
                        <tr>
                          <td>{item.name}</td>
                          <td
                            className="text-right"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                              gap: "15px",
                            }}
                          >
                            <EditIcon
                              onClick={() => handleModal(item)}
                              sx={{
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                              }}
                              fontSize="medium"
                            />
                            <DeleteIcon
                              onClick={() => handleDelete(item._id)}
                              sx={{
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                              }}
                              fontSize="medium"
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <div>
                      <p className="col">No data found</p>
                    </div>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      {addModal && (
        <AddDepartment
          addModal={addModal}
          handleAddModal={handleAddModal}
          getDepartment={getDepartment}
        />
      )}
      {openModal && (
        <EditDepartment
          openModal={openModal}
          handleModal={handleModal}
          department={currentDepartment || { name: "", _id: "" }} // Pass current category or empty data for new
          // setDepartment={setDepartment}
          getDepartment={getDepartment}
        />
      )}
    </>
  );
};

export default Department;
