/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { UpdateStore } from "StoreContext";

const EditManager = ({
  openModal,
  handleModal,
  managers,
  setManagers,
  getManagers,
}) => {
  console.log("🚀 ~ managers:", managers);
  const [departments, setDepartments] = useState([]);
  const update = UpdateStore();

  useEffect(() => {
    getDepartment();
  }, []);

  const getDepartment = async () => {
    try {
      const res = await api("get", "/department");
      setDepartments(res?.department || []); // Ensure data is set correctly
    } catch (error) {
      console.error("Error fetching departments", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setManagers((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !managers.firstName ||
      !managers.lastName ||
      !managers.email ||
      !managers.department
    ) {
      toast.error("All fields are required");
    } else {
      api("put", `/managers/${managers._id}`, managers).then((res) => {
        toast.success("Managers update successfully");
        const updatedManager = res.data; // Assuming API returns updated manager in `res.data`

        update({ manager: updatedManager });

        getManagers();
        handleModal();
      });
    }
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Edit a manager
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">First Name</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter first name here"
                          type="text"
                          value={managers.firstName}
                          name="firstName"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Last Name</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter last name here"
                          type="text"
                          value={managers.lastName}
                          name="lastName"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Email
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Enter email"
                          type="text"
                          value={managers.email}
                          name="email"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Department
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Enter Department"
                          type="select"
                          value={managers.department ?? ""} // Ensure no undefined value
                          name="department"
                          onChange={handleChange}
                        >
                          <option value="" disabled>
                            Select a department
                          </option>
                          {departments.map((dept, index) => (
                            <option key={index} value={dept.name}>
                              {dept.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">Role</label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter Role"
                          type="text"
                          value={managers.role}
                          name="role"
                          onChange={handleChange}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Update
          </Button>
          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditManager;
