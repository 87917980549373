/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Candidates from "views/candidates/Candidates";
import Managers from "views/managers/Managers";
import Archived from "views/archivedData/Archived";
import Register from "views/Register.js";
import Login from "views/Login.js";
import Questions from "views/questions/Questions";
import Department from "views/department/department";
// import CustomCategory from "views/customCategory/CustomCategory";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ComputerIcon from "@mui/icons-material/Computer";
import PersonIcon from "@mui/icons-material/Person";
// import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
// import QuizIcon from "@mui/icons-material/Quiz";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
// import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
// import CategoryIcon from "@mui/icons-material/Category";

const role = localStorage.getItem("role");

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: <ComputerIcon />,
    component: Index,
    layout: "/admin",
  },
  {
    path: "/candidates",
    name: "All Candidates",
    icon: <PersonIcon />,
    component: Candidates,
    layout: "/admin",
  },
  ...(role !== "manager"
    ? [
        {
          path: "/managers",
          name: "All Managers",
          icon: <SupervisorAccountIcon />,
          component: Managers,
          layout: "/admin",
        },
      ]
    : []),
  // {
  //   path: "/invited-candidates",
  //   name: "Invited Candidates",
  //   icon: <QuizIcon />,
  //   component: Candidates,
  //   layout: "/admin",
  // },
  // {
  //   path: "/assessment-participants",
  //   name: "Assessment Participants",
  //   icon: <DomainVerificationIcon />,
  //   component: Candidates,
  //   layout: "/admin",
  // },
  ...(role !== "manager"
    ? [
        {
          path: "/department",
          name: "Departments",
          icon: <RecentActorsIcon />,
          component: Department,
          layout: "/admin",
        },
      ]
    : []),

  {
    path: "/archived-result",
    name: "Archived Results",
    icon: <TurnedInIcon />,
    component: Archived,
    layout: "/admin",
  },
  {
    path: "/test",
    name: "Tests",
    icon: <QuestionAnswerIcon />,
    component: Questions,
    layout: "/admin",
  },

  // {
  //   path: "/custom-category",
  //   name: "Test Category",
  //   icon: <CategoryIcon />,
  //   component: CustomCategory,
  //   layout: "/admin",
  // },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-key-25 text-info",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-circle-08 text-pink",
    component: Login,
    layout: "/auth",
  },
];
export default routes;
