import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Button,
  Dropdown,
} from "reactstrap";
import api from "api/index";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// core components
import Header from "components/Headers/Header.js";
import EditQuestion from "./EditQuestion";
import AddQuestion from "./AddNewQuestion";
import qs from "qs";

const Questions = () => {
  const history = useHistory();
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("ALL");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    getQuestions();
  }, []);
  const handleModal = () => {
    setOpenModal((open) => !open);
    return openModal;
  };
  const { userId } = useParams();

  const getQuestions = async () => {
    setLoading(true);
    const res = await api("get", `/questions/${userId}`);
    setQuestions(res?.questions);
    setLoading(false);
  };
  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const handleDeleteQustn = (item) => {
    return api("delete", `/questions/${item?._id}`)
      .then((res) => {
        getQuestions();
        toast.success("Question deleted");
      })
      .catch((err) => {
        console.error("Error deleting question:", err);
        toast.error("Failed to delete question");
      });
  };
  const filter = (questions) => {
    if (selectedCategory === "ALL") {
      return questions;
    } else {
      return questions.filter((item) => item.category === selectedCategory);
    }
  };
  const handleEditModal = (item) => {
    setQuestion(item || question);
    setEditModal((open) => !open);
    return editModal;
  };

  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const skills = query.skills || [];
  console.log("🚀 ~ Questions ~ skills:", skills);
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">Question</h3>
                  <div className="d-flex align-items-center">
                    {/* <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                      <DropdownToggle caret color="primary">
                        {selectedCategory !== "ALL"
                          ? selectedCategory
                          : "Test Category"}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => setSelectedCategory("ALL")}
                        >
                          ALL
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setSelectedCategory("HTML")}
                        >
                          HTML
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setSelectedCategory("CSS")}
                        >
                          CSS
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setSelectedCategory("JavaScript")}
                        >
                          JavaScript
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setSelectedCategory("ReactJs")}
                        >
                          ReactJs
                        </DropdownItem>
                        <DropdownItem onClick={() => setSelectedCategory("RN")}>
                          RN
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setSelectedCategory("dotNet")}
                        >
                          dotNet
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setSelectedCategory("Global")}
                        >
                          Global
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setSelectedCategory("Node")}
                        >
                          Node
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setSelectedCategory("Express")}
                        >
                          Express
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown> */}
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={handleModal}
                      size="md"
                    >
                      <i className="ni ni-fat-add"></i>
                      Add Custom Question
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead
                  className="thead-light"
                  style={{
                    position: "sticky",
                    width: "100%",
                    top: "0px",
                  }}
                >
                  <tr>
                    <th scope="col">Question</th>
                    {/* <th scope="col">Number</th> */}
                    <th scope="col">Test Title</th>
                    <th scope="col">Type</th>

                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <h3 className="ml-3">Loading...</h3>
                  ) : (
                    <>
                      {filter(questions).length > 0 ? (
                        filter(questions)?.map((item) => {
                          return (
                            <tr>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <Media>
                                    <span
                                      className="mb-0 text-sm"
                                      title={item.text}
                                    >
                                      {item?.text}
                                    </span>
                                  </Media>
                                </Media>
                                {item?.options.map((option, optionIndex) => (
                                  <div
                                    key={optionIndex}
                                    className="d-flex flex-column justify-content-between mt-2"
                                  >
                                    <div className="w-33">
                                      <Media>
                                        <span
                                          className="mb-0 text-xs text-wrap"
                                          style={{
                                            color: option?.correct
                                              ? "#2ECE89"
                                              : "gray",
                                            fontWeight: option?.correct
                                              ? "900"
                                              : "600",
                                          }}
                                          title={option?.text}
                                        >
                                          {optionIndex + 1}
                                          {") "}
                                          {option?.text}
                                        </span>
                                      </Media>
                                    </div>
                                  </div>
                                ))}
                              </th>
                              {/* <td title={item?.question_number}>
                              {item?.question_number}
                            </td> */}
                              <td>{item?.category}</td>
                              <td>
                                {item?.type === "multipleChoice"
                                  ? "MCQS"
                                  : "Text"}
                              </td>

                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    {/* <DropdownItem
                                    onClick={() =>
                                      history.push(
                                        `/admin/question/${item.question_number}/${item.technology}`
                                      )
                                    }
                                  >
                                    View
                                  </DropdownItem> */}

                                    <DropdownItem
                                      href="#pablo"
                                      onClick={() => handleEditModal(item)}
                                    >
                                      Edit
                                    </DropdownItem>
                                    <DropdownItem
                                      // href="#pablo"
                                      onClick={() => handleDeleteQustn(item)}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div>
                          <p className="col">No data found</p>
                        </div>
                      )}
                    </>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      {editModal && (
        <EditQuestion
          openModal={editModal}
          handleModal={handleEditModal}
          question={question}
          setQuestion={setQuestion}
          getQuestions={getQuestions}
        />
      )}
      {openModal && (
        <AddQuestion
          skills={skills}
          openModal={openModal}
          handleModal={handleModal}
          getQuestions={getQuestions}
        />
      )}
    </>
  );
};

export default Questions;
