import { useEffect, useState } from "react";
import api from "../../api";
import {
  Card,
  CardHeader,
  Button,
  Table,
  Row,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
} from "reactstrap";
import { toast } from "react-toastify";
import EditCategory from "./EditCategory";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BookmarksIcon from "@mui/icons-material/Bookmarks";

const CustomCategory = ({ handleModalQuestion, handleScroll }) => {
  const [openModal, setOpenModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null); // For tracking which category is being edited

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    try {
      const data = await api("get", "/category");
      setCategories(data.category);
    } catch (error) {
      toast.error("Failed to fetch test title");
    }
  };

  const handleModal = (category = null) => {
    setCurrentCategory(category); // Set current category for editing
    setOpenModal(!openModal); // Toggle modal
  };

  const handleDelete = async (id) => {
    try {
      await api("delete", `/category/${id}`);
      toast.success("Test Title deleted successfully");
      getCategories();
    } catch (error) {
      toast.error("Failed to delete test title");
    }
  };

  return (
    <div
      style={{
        marginBottom: "3%",
        width: "100%",
      }}
    >
      <Row style={{ margin: 0, padding: 0 }}>
        <div className="col" style={{ margin: 0, padding: 0, width: "100%" }}>
          <Card className="shadow">
            <CardHeader className="border-0">
              <div className="d-flex justify-content-between">
                <h3 className="mb-0">Test Titles</h3>
                <div className="d-flex align-items-center">
                  <BookmarksIcon
                    style={{
                      marginRight: "20px",
                      cursor: "pointer",
                      color: "#5E72E4",
                    }}
                    onClick={handleScroll} // Attach the click handler
                  />
                  <Button
                    color="primary"
                    onClick={() => handleModal()}
                    size="md"
                  >
                    <i className="ni ni-fat-add"></i> Create Test Title
                  </Button>
                  <Button
                    color="primary"
                    href="#pablo"
                    onClick={handleModalQuestion}
                    size="md"
                  >
                    <i className="ni ni-fat-add"></i>
                    Add Question To a Test
                  </Button>
                </div>
              </div>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Test Title</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {categories.length > 0 ? (
                  categories.map((item) => (
                    <tr key={item._id}>
                      <td>{item.name}</td>
                      <td
                        className="text-right"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          gap: "15px",
                        }}
                      >
                        <EditIcon
                          sx={{
                            cursor: "pointer",
                            width: "20px",
                            height: "20px",
                          }}
                          onClick={() => handleModal(item)}
                          fontSize="medium"
                        />
                        <DeleteIcon
                          sx={{
                            cursor: "pointer",
                            width: "20px",
                            height: "20px",
                          }}
                          onClick={() => handleDelete(item._id)}
                          fontSize="medium"
                        />
                      </td>
                      {/* <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem onClick={() => handleModal(item)}>
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              className="text-danger"
                              onClick={() => handleDelete(item._id)}
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No categories found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card>
        </div>
      </Row>

      {openModal && (
        <EditCategory
          openModal={openModal}
          handleModal={handleModal}
          categories={currentCategory || { name: "", _id: "" }} // Pass current category or empty data for new
          setCategories={setCategories}
          getCategories={getCategories}
        />
      )}
    </div>
  );
};

export default CustomCategory;
