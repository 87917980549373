/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
import { Store } from "StoreContext";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  ModalHeader,
} from "reactstrap";
import MenuIcon from "@mui/icons-material/Menu";
import LockResetIcon from "@mui/icons-material/LockReset";
import api from "api";
import { Box, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const AdminNavbar = (props) => {
  const [open, setOpen] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const history = useHistory();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { user } = Store();
  const role = localStorage.getItem("role");
  const ManagerID = localStorage.getItem("ManagerID");

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const toggleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleLogout = () => {
    localStorage.setItem("token", "");
    window.location = "/auth/login";
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const chnagePassword = (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    api("patch", `/managers/${ManagerID}/changePassword`, formData, {}).then(
      (res) => {
        toast.success("Manager password is updated");
        history.push("/auth/login");
      }
    );
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/admin/index"
          >
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {/* {user.name ? user.name : "Setting"} */}
                      <MenuIcon />
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                {/* <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem>
                <DropdownItem divider /> */}
                <DropdownItem href="#pablo" onClick={handleLogout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
                {role === "manager" && (
                  <DropdownItem href="#pablo" onClick={handleOpen}>
                    <LockResetIcon />
                    <span>Change Password</span>
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalHeader
            style={{
              padding: "0",
              margin: "0",
              position: "absolute",
              right: "15px",
              top: "15px",
            }}
            charCode="X"
            toggle={handleClose}
          ></ModalHeader>
          <Row>
            <Col lg="12" style={{ margin: "0", width: "100%" }}>
              <FormGroup>
                <label className="form-control-label">Old Password</label>
                <div className="input-group">
                  <Input
                    className="form-control-alternative text-default"
                    required={true}
                    placeholder="Old Password"
                    type={showOldPassword ? "text" : "password"}
                    value={formData.oldPassword}
                    name="oldPassword"
                    onChange={handleChange}
                  />
                  <div className="input-group-append">
                    <IconButton onClick={toggleShowOldPassword}>
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12" style={{ margin: "0", width: "100%" }}>
              <FormGroup>
                <label className="form-control-label">New Password</label>
                <div className="input-group">
                  <Input
                    className="form-control-alternative text-default"
                    required={true}
                    placeholder="New Password"
                    type={showPassword ? "text" : "password"}
                    value={formData.newPassword}
                    name="newPassword"
                    onChange={handleChange}
                  />
                  <div className="input-group-append">
                    <IconButton onClick={toggleShowPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12" style={{ margin: "0" }}>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-role">
                  Confirm Password
                </label>
                <div className="input-group">
                  <Input
                    className="form-control-alternative text-default"
                    placeholder="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    value={formData.confirmPassword}
                    name="confirmPassword"
                    onChange={handleChange}
                  />
                  <div className="input-group-append">
                    <IconButton onClick={toggleShowConfirmPassword}>
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "5%",
            }}
          >
            <Button color="primary" onClick={chnagePassword}>
              Change Password
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AdminNavbar;
