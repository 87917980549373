import { Box } from "@mui/material";
import React from "react";
import {
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

const InputQuestionPopup = ({ handleClose, open, selectedQuestion }) => {
  return (
    <Modal isOpen={open} toggle={handleClose} centered>
      <ModalHeader charCode="X" toggle={handleClose} />
      <ModalBody>
        <Row>
          <Col lg="9" style={{ margin: "auto" }}>
            <FormGroup>
              <label className="form-control-label">Question</label>
              <Input
                className="form-control-alternative text-default"
                value={selectedQuestion?.question || ""}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="9" style={{ margin: "auto" }}>
            <FormGroup>
              <label className="form-control-label">Answer</label>
              <Input
                type="textarea"
                className="form-control-alternative text-default"
                value={selectedQuestion?.answer || ""}
                disabled
                style={{
                  resize: "none",
                  minHeight: "10em",
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default InputQuestionPopup;
