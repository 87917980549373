import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import { Select } from "antd";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
// core components

const { Option } = Select;

const EditAnswer = ({
  openModal,
  handleModal,
  answer,
  setAnswer,
  getAnswers,
}) => {
  let [skills] = useState([
    "HTML",
    "CSS",
    "JavaScript",
    "React",
    "Node",
    "Express",
  ]);

  const handleInput = (e) => {
    setAnswer({ ...answer, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!answer.answer || !answer.correct) {
      toast.error("All fields are required");
    } else {
      api("put", `/answers/${answer._id}`, answer).then((res) => {
        toast.success("Candidate added successfully");
        getAnswers();
        handleModal();
      });
    }
  };

  return (
    <>
      <Modal isOpen={openModal} size="md" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Edit answer
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="9" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Answer</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter candidate's name here"
                          type="text"
                          value={answer.answer}
                          name="answer"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="9" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Answer Status
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          type="select"
                          value={answer.correct}
                          name="correct"
                          onChange={handleInput}
                        >
                          <option value="">Select </option>
                          <option value="true">Correct</option>
                          <option value="false">In-correct</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Update
          </Button>

          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditAnswer;
