import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import { useParams } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";

const AddQuestion = ({ openModal, handleModal, getQuestions, skills }) => {
  console.log("🚀 ~ AddQuestion ~ skills:", skills);
  const [question, setQuestion] = useState({});
  const [categories, setCategories] = useState([]);
  const [isCustomCategory, setIsCustomCategory] = useState(false); // New state variable
  const { userId } = useParams();
  const [isChecked, setIsChecked] = useState(false);
  const [qstnType, setQstnType] = useState("multipleChoice");

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const res = await api("get", "/category");
      setCategories(res.category); // Ensure the backend returns categories in res.data.category
    } catch (error) {
      console.error("Error fetching test title:", error);
    }
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleInput = (e) => {
    setQuestion({ ...question, [e.target.name]: e.target.value });
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = categories.find(
      (category) => category._id === e.target.value
    );

    setIsCustomCategory(selectedCategory.name === "Custom Questions"); // Check if selected category is "Custom Questions"

    setQuestion({
      ...question,
      category: {
        id: selectedCategory._id,
        name: selectedCategory.name,
        custom: selectedCategory.custom, // Assuming 'custom' is a flag in your category data
      },
    });
  };

  useEffect(() => {
    if (isChecked) {
      setQstnType("input");
    } else {
      setQstnType("multipleChoice");
    }
  }, [isChecked]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isCustomCategory && !userId) {
      return toast.error(
        "This test title is only for candidate custom questions."
      );
    }

    let data = {
      text: question?.text,
      type: qstnType,
      category: userId ? skills[0] : question.category.name,
      specific_for: userId,
    };

    if (
      !question.text ||
      (!isChecked && !question.correctOption) ||
      (!isChecked && !question.option2)
    ) {
      toast.error("All fields are required");
    } else {
      if (!userId && !question.category) {
        return toast.error("Test Title required");
      }

      if (!isChecked) {
        data.options = [
          { text: question.correctOption, correct: true },
          { text: question.option2, correct: false },
          { text: question.option3, correct: false },
          { text: question.option4, correct: false },
        ];
      }

      const endpoint = userId ? `/questions/${userId}` : "/questions";
      api("post", endpoint, data).then((res) => {
        toast.success("Question added successfully");
        getQuestions();
        handleModal();
      });
    }
  };

  return (
    <>
      <Modal isOpen={openModal} size="md" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Add new question
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  {userId && skills[0] === "Custom Questions" ? (
                    <Row>
                      <Col lg="9" style={{ margin: "auto" }}>
                        <FormGroup>
                          <label className="form-control-label">
                            Test Title
                          </label>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={skills[0]}
                            name="category"
                            onChange={handleCategoryChange}
                            disabled
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col lg="9" style={{ margin: "auto" }}>
                        <FormGroup>
                          <label className="form-control-label">
                            Test Title
                          </label>
                          <Input
                            className="form-control-alternative text-default"
                            required={true}
                            type="select"
                            value={question.category?.id || ""}
                            name="category"
                            onChange={handleCategoryChange}
                          >
                            <option disabled value="">
                              Select Test Title
                            </option>
                            {categories.map((category) => (
                              <option key={category._id} value={category._id}>
                                {category.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col lg="9" style={{ margin: "auto", marginBottom: "0px" }}>
                      <FormGroup>
                        <label className="form-control-label">Question</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter question text"
                          type="text"
                          value={question?.text || ""}
                          name="text"
                          onChange={handleInput}
                          maxLength={250}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg="9"
                      style={{
                        marginBottom: "20px",
                        marginRight: "auto",
                        marginLeft: "auto",
                        marginTop: "-10px",
                      }}
                    >
                      <FormGroup check>
                        <Label check>
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            style={{ cursor: "pointer" }}
                          />{" "}
                          <label className="form-control-label">
                            Answer as text field?
                          </label>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  {!isChecked && (
                    <>
                      <Row>
                        <Col lg="9" style={{ margin: "auto" }}>
                          <FormGroup>
                            <label className="form-control-label">
                              Correct Option
                            </label>
                            <Input
                              className="form-control-alternative text-default"
                              required={true}
                              placeholder="Enter correct option"
                              type="text"
                              value={question.correctOption || ""}
                              name="correctOption"
                              onChange={handleInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="9" style={{ margin: "auto" }}>
                          <FormGroup>
                            <label className="form-control-label">
                              Other Options
                            </label>
                            <Input
                              className="form-control-alternative text-default"
                              required={true}
                              placeholder="Enter option text"
                              type="text"
                              value={question.option2 || ""}
                              name="option2"
                              onChange={handleInput}
                            />
                            <Input
                              style={{ marginTop: "15px" }}
                              className="form-control-alternative text-default"
                              required={true}
                              placeholder="Enter option text"
                              type="text"
                              value={question.option3 || ""}
                              name="option3"
                              onChange={handleInput}
                            />
                            <Input
                              style={{ marginTop: "15px" }}
                              className="form-control-alternative text-default"
                              required={true}
                              placeholder="Enter option text"
                              type="text"
                              value={question.option4 || ""}
                              name="option4"
                              onChange={handleInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Save
          </Button>
          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddQuestion;
