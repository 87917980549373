/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import { useEffect } from "react";
import api from "api";
import { Store, UpdateStore } from "StoreContext";
import { useHistory } from "react-router-dom";

import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.js";

// import ArticleIcon from "@mui/icons-material/Article";

const Archived = () => {
  const history = useHistory();

  const { archivedData } = Store();
  console.log("🚀 ~ Archived ~ archivedData:", archivedData);
  const updateStore = UpdateStore();

  useEffect(() => {
    getManagers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getManagers = async () => {
    const data = await api("get", "/results/archived");
    updateStore({ archivedData: data?.result });
    // console.log("🚀 ~ getManagers ~ archivedDatasadas:", data?.result[0]);
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">Archived</h3>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead
                  className="thead-light"
                  style={{
                    position: "sticky",
                    width: "100%",
                    top: "0px",
                  }}
                >
                  <tr>
                    <th scope="col">Name</th>
                    {/* <th scope="col">Last Name</th> */}
                    <th scope="col">Email</th>
                    <th scope="col">Archived By</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {archivedData?.length > 0 ? (
                    [...archivedData].reverse().map((item) => {
                      return (
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media style={{ display: "flex", gap: "4px" }}>
                                <span
                                  className="mb-0 text-sm"
                                  title={item?.user?.firstName}
                                >
                                  {item?.user?.firstName}
                                </span>
                                <span
                                  className="mb-0 text-sm"
                                  title={item?.user?.lastName}
                                >
                                  {item?.user?.lastName}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          {/* <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span
                                  className="mb-0 text-sm"
                                  title={item?.user?.lastName}
                                >
                                  {item?.user?.lastName?.length > 30
                                    ? item?.user?.lastName?.substring(0, 30) +
                                      "..."
                                    : item?.user?.lastName}
                                </span>
                              </Media>
                            </Media>
                          </th> */}
                          <td title={item?.user?.email}>
                            {item?.user?.email?.length > 30
                              ? item?.user?.email?.substring(0, 30) + "..."
                              : item?.user?.email}
                          </td>

                          <td>
                            {item?.archivedBy?.firstName
                              ? item?.archivedBy?.firstName
                              : item?.archivedBy?.name}
                            {/* {item?.archivedBy?.lastName} */}
                          </td>
                          <td className="text-right">
                            {/* <ArticleIcon /> */}
                            <Button
                              style={{
                                backgroundColor: "#11CAEF30",
                                color: "#32325D",
                                padding: "8px 16px",
                              }}
                              onClick={() =>
                                history.push(
                                  `/admin/archived-result/${item?.user?._id}`
                                )
                              }
                            >
                              Result
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <div>
                      <p className="col">No data found</p>
                    </div>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Archived;
