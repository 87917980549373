/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

const AddDepartment = ({ addModal, handleAddModal, getDepartment }) => {
  const [formData, setFormData] = useState({
    name: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    api("post", `/department`, formData, {}).then((res) => {
      toast.success("Department created successfully");
      handleAddModal();
      getDepartment();
    });
  };

  return (
    <>
      <Modal isOpen={addModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleAddModal}>
          Create a Department
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="10" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter name here"
                          type="text"
                          value={formData.name}
                          name="name" // This should match the key in formData
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Create
          </Button>
          <Button onClick={handleAddModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddDepartment;
