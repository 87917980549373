// import { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import api from "../../api";
// import { Select } from "antd";
// import {
//   Modal,
//   ModalBody,
//   ModalHeader,
//   ModalFooter,
//   Button,
//   FormGroup,
//   Form,
//   Input,
//   Row,
//   Col,
// } from "reactstrap";
// // core components

// const EditCategory = ({
//   openModal,
//   handleModal,
//   categories,
//   setCategories,
//   getCategories,
// }) => {
//   const handleInput = (e) => {
//     setCategories({ ...categories, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!categories.name) {
//       toast.error("Category name is required");
//     } else {
//       try {
//         await api("put", `/category/${categories._id}`, categories);
//         toast.success("Category updated successfully");
//         getCategories();
//         handleModal();
//       } catch (error) {
//         console.error("Error updating category:", error);
//         toast.error("Error updating category");
//       }
//     }
//   };

//   return (
//     <>
//       <Modal isOpen={openModal} size="xl" centered>
//         <ModalHeader charCode="X" toggle={handleModal}>
//           Edit a Category
//         </ModalHeader>
//         <ModalBody>
//           <Row>
//             <Col>
//               <Form>
//                 <div className="pl-lg-4">
//                   <Row>
//                     <Col lg="8" style={{ margin: "auto" }}>
//                       <FormGroup>
//                         <label className="form-control-label">Name</label>
//                         <Input
//                           className="form-control-alternative text-default"
//                           required={true}
//                           placeholder="Enter name here"
//                           type="text"
//                           value={categories.name}
//                           name="name"
//                           onChange={handleInput}
//                         />
//                       </FormGroup>
//                     </Col>
//                   </Row>
//                 </div>
//               </Form>
//             </Col>
//           </Row>
//         </ModalBody>
//         <ModalFooter>
//           <Button color="primary" onClick={handleSubmit}>
//             Update
//           </Button>
//           <Button onClick={handleModal}>Cancel</Button>
//         </ModalFooter>
//       </Modal>
//     </>
//   );
// };

// export default EditCategory;

import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";

const EditCategory = ({
  openModal,
  handleModal,
  categories,
  getCategories,
}) => {
  const [categoryData, setCategoryData] = useState({
    name: categories.name || "", // Initialize with the passed category's name or empty string
    _id: categories._id || null, // Initialize with the passed category's id or null
  });

  useEffect(() => {
    if (categories) {
      setCategoryData({
        name: categories.name || "",
        _id: categories._id || null,
      });
    }
  }, [categories]);

  const handleInput = (e) => {
    setCategoryData({ ...categoryData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!categoryData.name) {
      toast.error("Test Title name is required");
    } else {
      try {
        if (categoryData._id) {
          // If category has an _id, update it
          await api("put", `/category/${categoryData._id}`, categoryData);
          toast.success("Test Title updated successfully");
        } else {
          // Otherwise, create a new category
          await api("post", `/category`, categoryData);
          toast.success("Test Title created successfully");
        }
        getCategories(); // Refresh categories list
        handleModal(); // Close modal
      } catch (error) {
        console.error("Error saving test title:", error);
        toast.error("Error saving test title");
      }
    }
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          {categoryData._id ? "Edit a Category" : "Add a Category"}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label">Name</label>
                <Input
                  className="form-control-alternative text-default"
                  required={true}
                  placeholder="Enter category name"
                  type="text"
                  value={categoryData.name}
                  name="name"
                  onChange={handleInput}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            {categoryData._id ? "Update" : "Create"}
          </Button>
          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditCategory;
